<template>
  <div id="page-user-view">
    <div id="user-data">

      <vx-card class="mb-10 m-0 rounded-none shadow-none pb-8">

        <!-- Avatar -->
        <div class="vx-row" style="align-items: center;">

          <!-- Avatar Col -->
          <vs-avatar size="130px" :src="image"/>
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <h1 class="mb-1">{{ name }}</h1>
            <h4> {{ className }} </h4>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      name: '',
      image: '',
      className: ''
    }
  },
  computed: {},
  methods: {},
  created() {
    let userData = this.$store.state.AppActiveUser;
    this.name = userData.fullName;
    this.image = userData.profileImage ? userData.profileImage : this.$defaultImage;
    this.className = userData.room.name;
  }
}

</script>

<style lang="scss">
#avatar-col {
  background: #c4dde9;
  border-radius: 50%;
  overflow: hidden;
  height: 130px;
  width: 130px;
}

#avatar-col .image-container {
  width: 100%;
  height: 100%;
}

#avatar-col .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

</style>
