<template>
  <div class="pt-5">
    <!-- <teacher-header></teacher-header> -->
    <h1 class="my-2" style="margin-left:2.2rem">Self Assesment</h1>
    <vx-card class="mb-base mt-10">
      <div class="">
        <table class="w-full table self-assessment-table">
          <template>
            <thead>
              <tr>
                <th>Core Skills</th>
                <th>Initial rating</th>
                <th>QR1</th>
                <th>QR2</th>
                <th>QR3</th>
                <th>QR4</th>
                <th>Professional Development</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(selfAssessment, index) in selfAssessments"
                :key="index"
              >
                <td>
                  <div class="demo-alignment">
                    <img
                      :src="selfAssessment.icon"
                      width="45"
                      height="45"
                      class="inline-block"
                      style="object-fit: scale-down"
                    />
                    {{ selfAssessment.name }}
                  </div>
                </td>

                <td
                  v-if="
                    selfAssessment.assessments.length == 0 ||
                      selfAssessment.assessments[0].initialRating == null
                  "
                >
                  <a
                    href="javascript::void(0)"
                    @click="addInitialRating()"
                    v-if="index == 0"
                    >Assess</a
                  >
                </td>
                <td v-else>
                  {{ selfAssessment.assessments[0].initialRating }}
                </td>
                <td
                  v-if="
                    selfAssessment.assessments.length > 0 &&
                      selfAssessment.assessments[0].firstQuater != null
                  "
                >
                  {{ selfAssessment.assessments[0].firstQuater }}
                </td>
                <td
                  v-else-if="
                    selfAssessment.assessments.length == 0 ||
                      selfAssessment.assessments[0].initialRating == null
                  "
                ></td>
                <td v-else>
                  <a href="#" @click="addAssessment(1)" v-if="index == 0"
                    >Assess</a
                  >
                </td>

                <td
                  v-if="
                    selfAssessment.assessments.length > 0 &&
                      selfAssessment.assessments[0].secondQuater != null
                  "
                >
                  {{ selfAssessment.assessments[0].secondQuater }}
                </td>
                <td
                  v-else-if="
                    selfAssessment.assessments.length == 0 ||
                      selfAssessment.assessments[0].firstQuater == null
                  "
                ></td>
                <td v-else>
                  <a href="#" @click="addAssessment(2)" v-if="index == 0"
                    >Assess</a
                  >
                </td>

                <td
                  v-if="
                    selfAssessment.assessments.length > 0 &&
                      selfAssessment.assessments[0].thirdQuater != null
                  "
                >
                  {{ selfAssessment.assessments[0].thirdQuater }}
                </td>
                <td
                  v-else-if="
                    selfAssessment.assessments.length == 0 ||
                      selfAssessment.assessments[0].secondQuater == null
                  "
                ></td>
                <td v-else>
                  <a href="#" @click="addAssessment(3)" v-if="index == 0"
                    >Assess</a
                  >
                </td>

                <td
                  v-if="
                    selfAssessment.assessments.length > 0 &&
                      selfAssessment.assessments[0].fourthQuater != null
                  "
                >
                  {{ selfAssessment.assessments[0].fourthQuater }}
                </td>
                <td
                  v-else-if="
                    selfAssessment.assessments.length == 0 ||
                      selfAssessment.assessments[0].thirdQuater == null
                  "
                ></td>
                <td v-else>
                  <a href="#" @click="addAssessment(4)" v-if="index == 0"
                    >Assess</a
                  >
                </td>
                <td>
                  <a
                    href="#"
                    class="text-center underline-link"
                    @click="detailPage(selfAssessment.assessments[0])"
                    v-if="selfAssessment.assessments.length != 0"
                  >
                    Professional Development
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </table>
      </div>
    </vx-card>
    <vs-popup
      fullscreen
      class="holamundo popup-example popup-large"
      title="Self Assessment"
      :active.sync="popupActive"
    >
      <h2 class="mb-8">Self Assessment - {{ addToQuater }}</h2>
      <div
        v-for="(selfAssessment, index) in selfAssessments"
        :key="index"
        class="mb-16"
      >
        <div class="demo-alignment mb-8">
          <strong class="w-full m-0 relative"
            >{{ index + 1 }}. {{ selfAssessment.name }}
            <vx-tooltip
              v-bind:text="selfAssessment.description"
              position="right"
              class="inline-block"
            >
              <a href="#">
                <feather-icon icon="InfoIcon" style="width: 15px;" />
              </a>
            </vx-tooltip>
          </strong>
        </div>
        <div class="range">
          <ul class="range-labels">
            <li>
              <input
                id="one"
                type="radio"
                v-model="addRating[selfAssessment._id]"
                value="1"
              />
              <label for="one">
                <span></span>
                1
              </label>
            </li>
            <li>
              <input
                id="two"
                type="radio"
                v-model="addRating[selfAssessment._id]"
                value="2"
              />
              <label for="two">
                <span></span>
                2
              </label>
            </li>
            <li>
              <input
                id="three"
                type="radio"
                v-model="addRating[selfAssessment._id]"
                value="3"
              />
              <label for="three">
                <span></span>
                3
              </label>
            </li>
            <li>
              <input
                id="four"
                type="radio"
                v-model="addRating[selfAssessment._id]"
                value="4"
              />
              <label for="four">
                <span></span>
                4
              </label>
            </li>
            <li>
              <input
                id="five"
                type="radio"
                v-model="addRating[selfAssessment._id]"
                value="5"
              />
              <label for="five">
                <span></span>
                5
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-between mt-20">
        <vs-button class="mr-3 mb-2" @click="submitData">Submit</vs-button>
        <vs-button
          color="primary"
          type="border"
          class="mb-2"
          @click="closePopup"
          >Cancel
        </vs-button>
      </div>
    </vs-popup>

    <FastCheckSelfAssessment></FastCheckSelfAssessment>

    <vx-card class="mb-base">
      <h2>Professional Development Log - Core Skills</h2>
      <div class="overflow-auto">
        <table class="w-full table professional-development-table" style="table-layout:auto;max-witdh:100%;">
          <template>
            <thead>
              <tr>
                <th>Date Viewed</th>
                <!-- <th>&nbsp;</th> -->
                <!-- <th>Core Skill</th> -->
                <th>Professional Development</th>
                <th>My Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(log, index) in documentLogs" :key="index">
                <td style="white-space: nowrap;">{{ formatDate(documentLogs[index].viewedAt) }}</td>
                <!-- <td>&nbsp;</td> -->
                <!-- <td>
                <div class="demo-alignment">
                  <img :src="documentLogs[index].assessmentPresetId.icon" width="45" height="45" class="inline-block"
                       style="object-fit: scale-down">
                  {{ documentLogs[index].assessmentPresetId.name }}
                </div>
              </td> -->
                <td id="special-td">
                  <!--                <a href="#" class="text-center underline-link"
                                   @click="documentDetailPage(documentLogs[index].assessmentPresetId._id,documentLogs[index].rating)">
                                  Professional Developments
                                </a>-->

                  <a
                    href="#"
                    class="text-center underline-link"
                    @click="resourcePage(documentLogs[index])"
                  >
                    <div class="text-left" v-if="documentLogs[index].resource">
                      {{
                        getLogResourceTitle(
                          documentLogs[index].resource.resources,
                          documentLogs[index].resourceId
                        )
                      }}
                    </div>
                    <div v-else>
                      Professional Developments
                    </div>
                  </a>
                </td>
                <td style="width:600px;">
                  <div class="inline-flex items-center w-full justify-between">
                    <div class="notes-wrap w-full">
                      <div v-if="log.noteSaved && !log.editing">
                        {{ documentLogs[index].note }}
                      </div>
                      <div v-else>
                        <vs-textarea
                          :label="'Description'"
                          :class="documentLogs[index].note ? 'hasValue' : ''"
                          v-model="documentLogs[index].note"
                          name="description"
                          v-validate="'required'"
                        />
                        <span
                          class="text-danger text-sm"
                          v-show="errors.has('description')"
                          >{{ errors.first("description") }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="flex"
                      v-if="!log.noteSaved || log.editing"
                    >
                      <vs-button
                        class="ml-3"
                        color="primary"
                        type="border"
                        :disabled="!isFormValid(index)"
                        @click="saveLogNote(index)"
                        >Save
                      </vs-button>
                      <vs-button
                        class="ml-3"
                        v-if="log.editing"
                        color="primary"
                        type="border"
                        @click="cancelLogNote(index)"
                        style="width: 50%;"
                        >Cancel
                      </vs-button>
                    </div>
                    <div v-else>
                      <vs-button
                        class="ml-3"
                        color="primary"
                        type="border"
                        :disabled="!isFormValid(index)"
                        @click="editLogNote(index)"
                        >Edit
                      </vs-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </table>
        <div
          class="pagination-div flex justify-center float-none mt-5"
          v-if="serverResponded"
        >
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="leftIcon"
            :nextText="rightIcon"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import TeacherHeader from "../TeacherHeader";
import { mapActions } from "vuex";
import FastCheckSelfAssessment from "./FastCheckSelfAssessment";

export default {
  data() {
    return {
      totalDocs: 0,
      dataTableParams: {
        limit: 10,
        teacherId: this.$store.state.AppActiveUser._id,
        page: 1
      },

      rightIcon: '<i class="feather icon-arrow-right"></i>',
      leftIcon: '<i class="feather icon-arrow-left"></i>',
      serverResponded: false,

      popupActive: false,
      teacherId: null,
      selfAssessments: [],
      selfAssesmentsFastCheck: [],
      addRatingFastCheck: {},
      documentLogs: [],
      addToQuater: "QTR1",
      addRating: {},
      quater: null,
      initialRating: false,

      manipulateLog: []
    };
  },
  methods: {
    ...mapActions("selfAssessment", [
      "addSelfAssessment",
      "getAssessments",
      "getDocumentLogs",
      "saveNote",
      "getAssessmentsFastCheck"
    ]),
    resourcePage(data) {
      if (data.resource) {
        //console.log(data.resource.resources[0].link);
        if (data.resource.resources[0].link) {
          window.open(data.resource.resources[0].link, "_blank");
          //window.location.href = data.resource.resources[0].link;
        } else {
          window.open(data.resource.resources[0].document, "_blank");
          //window.location.href = data.resource.resources[0].link.document;
        }
      }

      /*if(resource.resource){

        if(resource.resource.link){
          window.location.href = resource.resource.link;
        }else{
          window.location.href = resource.resource.document;
        }

      }*/
    },

    cancelLogNote(index) {
      this.documentLogs[index].editing = false;
      this.documentLogs[index].note = this.documentLogs[index].oldNote;
    },
    editLogNote(index) {
      this.documentLogs[index].editing = true;
    },
    formatDate(date) {
      return date.slice(0, 10);
    },
    detailPage(assessment) {
      let rating = assessment.initialRating;
      let coreSkillId = assessment.assessmentPresetId;
      if (assessment.fourthQuater != null) {
        rating = assessment.fourthQuater;
      } else if (assessment.thirdQuater != null) {
        rating = assessment.thirdQuater;
      } else if (assessment.secondQuater != null) {
        rating = assessment.secondQuater;
      } else if (assessment.firstQuater != null) {
        rating = assessment.firstQuater;
      }

      let routeData = this.$router.resolve({
        name: "professional-development-detail",
        params: { coreSkillId, rating }
      });
      window.open(routeData.href, "_blank");
    },
    closePopup() {
      this.popupActive = false;
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    async submitData() {
      const self = this;
      this.$vs.loading();
      let data = {
        ratings: self.addRating,
        teacherId: self.teacherId,
        //year: "2020",
        quater: self.quater,
        initialRating: self.initialRating
      };
      await this.addSelfAssessment(data)
        .then(res => {
          self.getAssessmentsList(this.teacherId);
          self.$vs.loading.close();
          self.popupActive = false;
          self.showMessage(
            "Success",
            "Self assessment successfully.",
            "success"
          );
        })
        .catch(err => {
          console.log(err);
          this.$vs.loading.close();
        });
    },
    addAssessment(quarter) {
      this.popupActive = true;
      this.quater = quarter;
      this.initialRating = false;

      if (quarter == 1) {
        this.quarter = "QTR1";
      } else if (quarter == 2) {
        this.addToQuater = "QTR2";
      } else if (quarter == 3) {
        this.addToQuater = "QTR3";
      } else if (quarter == 4) {
        this.addToQuater = "QTR4";
      }
    },

    addInitialRating() {
      this.popupActive = true;
      this.addToQuater = "Initial Rating";
      this.initialRating = true;
      this.quater = null;
    },

    async getAssessmentsList(teacherId) {
      let self = this;
      await this.getAssessments(teacherId)
        .then(res => {
          self.selfAssessments = res.data.data;
          _.forEach(self.selfAssessments, function(value) {
            const assessmentId = value._id;
            self.addRating[assessmentId] = "3";
          });

          let formatJsonData = JSON.stringify(self.addRating);
          self.addRating = JSON.parse(formatJsonData);
        })
        .catch(err => {
          if (err.response.status === 403) {
            alert(
              "You dont have permission to visit this page. Please upgrade your package."
            );
            this.$router.push("/teacher/dashboard");
          }
        });
    },

    documentDetailPage(coreSkillId, rating) {
      let routeData = this.$router.resolve({
        name: "professional-development-detail",
        params: { coreSkillId, rating }
      });
      window.open(routeData.href, "_blank");
    },
    saveLogNote(index) {
      const data = {
        id: this.documentLogs[index]._id,
        teacherId: this.teacherId,
        note: this.documentLogs[index].note
      };

      this.$vs.loading();

      this.saveNote(data)
        .then(res => {
          let $this = this;

          $this.documentLogs[index].editing = false;
          $this.documentLogs[index].noteSaved = true;
          $this.documentLogs[index].oldNote = this.documentLogs[index].note;

          this.showMessage("Success", "Note added Successfully", "success");
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getDocumentLogsList();
    },
    isFormValid(index) {
      return !this.errors.any() && this.documentLogs[index].note;
    },
    getLogResourceTitle(logs, resourceId) {
      let title = "";
      _.forEach(logs, function(log) {
        if (log._id == resourceId) {
          title = log.title;
        }
      });
      return title;
    },
    getDocumentLogsList() {
      this.getDocumentLogs(this.dataTableParams)
        .then(res => {
          this.documentLogs = res.data.data.docs;

          this.documentLogs = this.documentLogs.map(function(el) {
            let o = Object.assign({}, el);

            let logs = o.assessmentPresetId.professionalDevelopmentResources.filter(
              function(el) {
                return el.rating == o.rating;

                // if(el.rating ==  o.rating){

                //   return el.resources.filter(function (item){
                //     console.log("test")
                //     if(item._id ==  o.resourceId){
                //       console.log(item);
                //     }
                //     return item._id ==  o.resourceId
                //   });

                // }else{
                //   return false;
                // }
              }
            );

            o.editing = false;
            o.resource = logs[0] || null;
            o.oldNote = o.note;
            return o;
          });

          console.log(this.documentLogs, "2");

          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;

          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    }
  },
  mounted() {
    this.teacherId = this.$store.state.AppActiveUser._id;
    this.$vs.loading();
    this.getAssessmentsList(this.teacherId);
    this.getDocumentLogsList();
  },
  components: { TeacherHeader, FastCheckSelfAssessment },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  }
};
</script>

<style lang="scss">
table {
  border-collapse: collapse;
}

table tr td {
  border-bottom: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  padding: 10px;
}

table tr td:first-child {
  border-left: none;
}

.vs-tooltip {
  z-index: 100000;
}

.demo-alignment {
  img {
    margin-top: 0 !important;
  }
}

.professional-development-table {
  th {
    &:first-child {
      min-width: 120px;
      width: 120px;
      @media (min-width: 1200px) {
        min-width: 160px;
        width: 160px;
      }
      @media (min-width: 1480px) {
        min-width: 200px;
        width: 200px;
      }
    }

    // &:nth-child(2){
    //   min-width: 120px;
    //   width: 120px;
    //   @media (min-width: 1200px){
    //     min-width: 250px;
    //     width: 250px;
    //   }
    //   @media (min-width: 1480px){
    //     min-width: 300px;
    //     width: 300px;
    //   }
    // }
    // &:nth-child(3) {
    //   min-width: 300px;
    //   width: 300px;
    //   @media (min-width: 1480px){
    //     min-width: 590px;
    //     width: 590px;
    //   }
    // }
  }

  td {
    &:nth-child(4) {
      .notes-wrap {
        width: 350px;
        @media (min-width: 1440px) {
          width: 450px;
        }
      }

      .ml-auto {
        width: 180px;
        @media (min-width: 1440px) {
          width: 240px;
        }
      }
    }
  }

  .vs-con-textarea {
    margin-bottom: 0;
  }

  .vs-textarea {
    min-height: 60px;
    height: 60px;
  }

  .vs-button {
    padding: 10px !important;
  }
}

.vs-popup {
  @media (min-width: 1480px) {
    width: 800px !important;
  }

  .vs-popup--content {
    padding: 30px;
  }

  .vs-popup--header {
    height: 0;

    .vs-popup--title {
      h3 {
        display: none;
      }
    }
  }

  .vs-popup--close {
    transform: none !important;
    background: none !important;
    box-shadow: none;
    position: relative;
    top: 35px;
    right: 20px;
    font-size: 25px;
    color: #2b2d3d;

    &:hover {
      color: #2d9ad5;
    }
  }
}

.range {
  position: relative;
  width: 100%;
  height: 5px;

  input[type="radio"] {
    border: 13.5px solid #2898d5;
    border-radius: 50%;
    width: 27px;
    position: absolute;
    height: 27px;
    -webkit-appearance: none;
    top: -12px;
    right: -12px;
    transition: 0.4s all ease-in-out;
    z-index: 1;
    opacity: 0;

    &:checked {
      opacity: 1;
    }
  }

  .range-labels {
    background: #f0f0f0;
    height: 6px;
    border-radius: 3px;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      position: relative;
      width: calc(100% / 5);
      text-align: left;
      color: black;
      font-size: 14px;
      height: 6px;
      top: -9px;
      cursor: pointer;

      &:first-child {
        width: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: -4px;
        right: 0;
        width: 3px;
        height: 13px;
        background: #606060;
      }

      label {
        position: absolute;
        top: 20px;
        right: -2px;
      }
    }
  }
}

.tag {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-block;
  background: #2898d5;
  position: relative;
  top: 5px;
  margin: 0 5px;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}
</style>
